<template>
  <Dialog
    :visible.sync="show"
    :style="{ width: $isMobile() ? '100vw' : '50vw', position: 'relative' }"
    :header="'Criar ticket'"
    :modal="true"
    :closable="false"
  >
    <form
      name="ticketForm"
      class="p-col-12"
      @submit.prevent="submitTicket"
      style="min-height: 15vh"
    >
      <div class="p-fluid p-formgrid p-grid" style="min-height: 20vh">
        <div class="p-field p-col-12 p-md-12">
          <label for="client_email">Email do Cliente</label>
          <InputText
            name="client_email"
            id="client_email"
            v-model="ticket.client_email"
            type="email"
            v-validate="'required'"
            v-bind:class="[
              { 'p-invalid': errors.has('client_email') },
              'form-control',
            ]"
            class="p-inputtext p-component"
          />
          <small v-if="errors.has('client_email')" class="p-error" role="alert">
            Email do Cliente é obrigatório
          </small>
        </div>
        <div class="p-field p-col-12 p-md-12">
          <label for="subject">Assunto</label>
          <InputText
            name="subject"
            id="subject"
            v-model="ticket.subject"
            v-validate="'required'"
            v-bind:class="[
              { 'p-invalid': errors.has('subject') },
              'form-control',
            ]"
          />
          <small v-if="errors.has('subject')" class="p-error" role="alert">
            Assunto é obrigatório
          </small>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="assigned_to">Responsável</label>
          <Dropdown
            name="assigned_to"
            id="assigned_to"
            :filter="true"
            v-model="ticket.assigned_to"
            :options="userList"
            :optionLabel="employeeLabel"
            dataKey="technician_id"
            :optionValue="'technician_id'"
            v-validate="'required'"
            v-bind:class="[
              { 'p-invalid': errors.has('assigned_to') },
              'form-control',
            ]"
          />
          <small v-if="errors.has('assigned_to')" class="p-error" role="alert">
            Responsável é obrigatório
          </small>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="status_new">Estado</label>
          <Dropdown
            name="status"
            id="status"
            v-model="ticket.status"
            :options="statusList"
            optionLabel="name"
            dataKey="status"
            optionValue="status"
            v-validate="'required'"
            v-bind:class="[
              { 'p-invalid': errors.has('status') },
              'form-control',
            ]"
          />
          <small v-if="errors.has('status')" class="p-error" role="alert">
            Estado é obrigatório
          </small>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="priority">Prioridade</label>
          <Dropdown
            name="priority"
            id="priority"
            v-model="ticket.priority"
            :options="priorityList"
            optionLabel="name"
            dataKey="priority"
            optionValue="priority"
            v-validate="'required'"
            v-bind:class="[
              { 'p-invalid': errors.has('priority') },
              'form-control',
            ]"
          />
          <small v-if="errors.has('priority')" class="p-error" role="alert">
            Prioridade é obrigatório
          </small>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="category">Categoria</label>
          <Dropdown
            name="category"
            id="category"
            v-model="ticket.category"
            :options="categoryList"
            optionLabel="name"
            dataKey="id"
            optionValue="id"
            v-validate="'required'"
            v-bind:class="[
              { 'p-invalid': errors.has('category') },
              'form-control',
            ]"
          />
          <small v-if="errors.has('category')" class="p-error" role="alert">
            Categoria é obrigatório
          </small>
        </div>

        <div class="p-field p-col-12 p-md-12">
          <label for="description">Descrição</label>
          <Textarea
            name="description"
            id="description"
            v-model="ticket.description"
            rows="4"
            v-validate="'required'"
            v-bind:class="[
              { 'p-invalid': errors.has('description') },
              'form-control',
            ]"
          />
          <small v-if="errors.has('description')" class="p-error" role="alert">
            Descrição é obrigatório
          </small>
        </div>
      </div>
    </form>
    <template #footer>
      <Button
        type="submit"
        label="Criar Ticket"
        icon="pi pi-check"
        class="p-button-success"
        @click="submitTicket"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-danger"
        @click="cancel"
      />
    </template>
  </Dialog>
</template>

<script>
import ticketsService from "../../services/tickets.service";
import employeeService from "../../services/employee.service";

export default {
  name: "CreateTicket",
  props: ["show", "showToast"],
  watch: {
    show() {
      if (this.show == true) {
        this.$validator.resume();
        return (this.ticket = {
          subject: null,
          description: null,
          assigned_to: null,
          status: null,
          priority: null,
          category: null,
          client_email: null,
        });
      }
    },
  },
  data() {
    return {
      ticket: {
        subject: null,
        description: null,
        assigned_to: null,
        status: null,
        priority: null,
        category: null,
        client_email: null,
      },
      userList: [],
      statusList: [],
      priorityList: [],
      categoryList: [],
    };
  },
  async created() {
    this.statusList = await ticketsService.getStatusList();
    this.userList = await employeeService.getActives();
    this.priorityList = await ticketsService.getPriorityList();
    this.categoryList = await ticketsService.getCategoryList();
  },
  methods: {
    employeeLabel(info) {
      return `${info.username} | ${info.department}`;
    },
    cancel() {
    this.$validator.pause();
    this.$validator.reset();
      this.ticket = {
        subject: null,
        description: null,
        assigned_to: null,
        status: null,
        priority: null,
        category: null,
        client_email: null,
      };
      return this.$emit("cancel");
    },
    submitTicket() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();

        let ticketData = {
          subject: this.ticket.subject,
          description: this.ticket.description,
          assigned_to: this.ticket.assigned_to,
          status: this.ticket.status,
          priority: this.ticket.priority,
          category: this.ticket.category,
          client_email: this.ticket.client_email,
        };

        this.loading = true;
        return ticketsService
          .createTicket(ticketData)
          .then((response) => {
            this.loading = false;
            if (!response) {
              return this.$toast.add({
                severity: "error",
                summary: "Erro",
                detail: "Ocorreu um erro ao criar um novo ticket",
                life: 3000,
              });
            }
            this.$toast.add({
              severity: "success",
              summary: "Ticket criado",
              detail: "O ticket foi criado com sucesso",
              life: 3000,
            });
            return this.$emit("createdTicket", {
              status: "ok",
              ticket: response,
            });
          })
          .catch((error) => {
            this.loading = false;
            console.error("Error creating ticket:", error);
            this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao processar a requisição",
              life: 3000,
            });
          });
      });
    },
  },
};
</script>

<style scoped>
.p-d-flex {
  display: flex;
}

.p-jc-between {
  justify-content: space-between;
}
</style>
