var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Dialog',{style:({ width: _vm.$isMobile() ? '100vw' : '50vw', position: 'relative' }),attrs:{"visible":_vm.show,"header":'Criar ticket',"modal":true,"closable":false},on:{"update:visible":function($event){_vm.show=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('Button',{staticClass:"p-button-success",attrs:{"type":"submit","label":"Criar Ticket","icon":"pi pi-check"},on:{"click":_vm.submitTicket}}),_c('Button',{staticClass:"p-button-danger",attrs:{"label":"Cancelar","icon":"pi pi-times"},on:{"click":_vm.cancel}})]},proxy:true}])},[_c('form',{staticClass:"p-col-12",staticStyle:{"min-height":"15vh"},attrs:{"name":"ticketForm"},on:{"submit":function($event){$event.preventDefault();return _vm.submitTicket.apply(null, arguments)}}},[_c('div',{staticClass:"p-fluid p-formgrid p-grid",staticStyle:{"min-height":"20vh"}},[_c('div',{staticClass:"p-field p-col-12 p-md-12"},[_c('label',{attrs:{"for":"client_email"}},[_vm._v("Email do Cliente")]),_c('InputText',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"p-inputtext p-component",class:[
            { 'p-invalid': _vm.errors.has('client_email') },
            'form-control',
          ],attrs:{"name":"client_email","id":"client_email","type":"email"},model:{value:(_vm.ticket.client_email),callback:function ($$v) {_vm.$set(_vm.ticket, "client_email", $$v)},expression:"ticket.client_email"}}),(_vm.errors.has('client_email'))?_c('small',{staticClass:"p-error",attrs:{"role":"alert"}},[_vm._v(" Email do Cliente é obrigatório ")]):_vm._e()],1),_c('div',{staticClass:"p-field p-col-12 p-md-12"},[_c('label',{attrs:{"for":"subject"}},[_vm._v("Assunto")]),_c('InputText',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[
            { 'p-invalid': _vm.errors.has('subject') },
            'form-control',
          ],attrs:{"name":"subject","id":"subject"},model:{value:(_vm.ticket.subject),callback:function ($$v) {_vm.$set(_vm.ticket, "subject", $$v)},expression:"ticket.subject"}}),(_vm.errors.has('subject'))?_c('small',{staticClass:"p-error",attrs:{"role":"alert"}},[_vm._v(" Assunto é obrigatório ")]):_vm._e()],1),_c('div',{staticClass:"p-field p-col-12 p-md-6"},[_c('label',{attrs:{"for":"assigned_to"}},[_vm._v("Responsável")]),_c('Dropdown',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[
            { 'p-invalid': _vm.errors.has('assigned_to') },
            'form-control',
          ],attrs:{"name":"assigned_to","id":"assigned_to","filter":true,"options":_vm.userList,"optionLabel":_vm.employeeLabel,"dataKey":"technician_id","optionValue":'technician_id'},model:{value:(_vm.ticket.assigned_to),callback:function ($$v) {_vm.$set(_vm.ticket, "assigned_to", $$v)},expression:"ticket.assigned_to"}}),(_vm.errors.has('assigned_to'))?_c('small',{staticClass:"p-error",attrs:{"role":"alert"}},[_vm._v(" Responsável é obrigatório ")]):_vm._e()],1),_c('div',{staticClass:"p-field p-col-12 p-md-6"},[_c('label',{attrs:{"for":"status_new"}},[_vm._v("Estado")]),_c('Dropdown',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[
            { 'p-invalid': _vm.errors.has('status') },
            'form-control',
          ],attrs:{"name":"status","id":"status","options":_vm.statusList,"optionLabel":"name","dataKey":"status","optionValue":"status"},model:{value:(_vm.ticket.status),callback:function ($$v) {_vm.$set(_vm.ticket, "status", $$v)},expression:"ticket.status"}}),(_vm.errors.has('status'))?_c('small',{staticClass:"p-error",attrs:{"role":"alert"}},[_vm._v(" Estado é obrigatório ")]):_vm._e()],1),_c('div',{staticClass:"p-field p-col-12 p-md-6"},[_c('label',{attrs:{"for":"priority"}},[_vm._v("Prioridade")]),_c('Dropdown',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[
            { 'p-invalid': _vm.errors.has('priority') },
            'form-control',
          ],attrs:{"name":"priority","id":"priority","options":_vm.priorityList,"optionLabel":"name","dataKey":"priority","optionValue":"priority"},model:{value:(_vm.ticket.priority),callback:function ($$v) {_vm.$set(_vm.ticket, "priority", $$v)},expression:"ticket.priority"}}),(_vm.errors.has('priority'))?_c('small',{staticClass:"p-error",attrs:{"role":"alert"}},[_vm._v(" Prioridade é obrigatório ")]):_vm._e()],1),_c('div',{staticClass:"p-field p-col-12 p-md-6"},[_c('label',{attrs:{"for":"category"}},[_vm._v("Categoria")]),_c('Dropdown',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[
            { 'p-invalid': _vm.errors.has('category') },
            'form-control',
          ],attrs:{"name":"category","id":"category","options":_vm.categoryList,"optionLabel":"name","dataKey":"id","optionValue":"id"},model:{value:(_vm.ticket.category),callback:function ($$v) {_vm.$set(_vm.ticket, "category", $$v)},expression:"ticket.category"}}),(_vm.errors.has('category'))?_c('small',{staticClass:"p-error",attrs:{"role":"alert"}},[_vm._v(" Categoria é obrigatório ")]):_vm._e()],1),_c('div',{staticClass:"p-field p-col-12 p-md-12"},[_c('label',{attrs:{"for":"description"}},[_vm._v("Descrição")]),_c('Textarea',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[
            { 'p-invalid': _vm.errors.has('description') },
            'form-control',
          ],attrs:{"name":"description","id":"description","rows":"4"},model:{value:(_vm.ticket.description),callback:function ($$v) {_vm.$set(_vm.ticket, "description", $$v)},expression:"ticket.description"}}),_vm._v(" "),(_vm.errors.has('description'))?_c('small',{staticClass:"p-error",attrs:{"role":"alert"}},[_vm._v(" Descrição é obrigatório ")]):_vm._e()],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }